* {
  padding: 0;
  margin: 0;
  font-family: "Nunito", sans-serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mt5 {
  margin-top: 5px;
}

.mb5 {
  margin-bottom: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.center {
  text-align: center;
}

.card-header {
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 786px) {
    flex-direction: column;
  }
}
.card-header-lower {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.card-right-toggles {
  display: flex;
  @media only screen and (max-width: 786px) {
    padding-top: 7px; // flex-direction: column;
  }
}
.product-card-hover {
  transition:  box-shadow .5s ease-out;
}
.product-card-hover:hover{
  cursor: pointer;
  box-shadow: 1px 4px 20px grey;
  transition:  box-shadow .5s ease-in;
  .product-title {
    transition:  color .5s ease-in;
    color: #0075FF;
  }
  .product-icon {
    transition: opacity .5s ease-in;
    opacity: 1;
  }
  .product-icon-wrapper {
    transition:  margin-left .5s ease-in;
    margin-left: 6px;
  }
}

.product-icon {
  margin-top: 6px;
  opacity: 0;
}

.card-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
// .card-right-toggles > .tabular {
//   // margin-left: 10px;
// }

.card-title {
  font-weight: bold;
  color: #11a1f1;
  font-size: 20px;
  @media only screen and (max-width: 786px) {
    font-size: 16px;
  }
}

.tabular {
  background: #f1f4f9;
  border-radius: 20px;
  padding: 5px 20px;
  cursor: pointer;
}

.pos-inc {
  color: #019d5c;
  font-weight: bold;
}

.neg-inc {
  color: #dd4438;
  font-weight: bold;
}

.big-number {
  color: #212145;
  font-size: 36px;
  font-weight: 900;
  margin: 0;
  margin-bottom: -10px;
  @media only screen and (max-width: 786px) {
    font-size: 30px;
  }
}
.scrollable {
  max-height: 250px;
  overflow-y: scroll;
}
.scrollable-large {
  max-height: 60vh;
  overflow-y: scroll;
}

.pad-right {
  padding-right: 10px;
}

// .collapse {
//   line-height: 0;
// }

.viz-offset {
  margin-top: 15px;
}

.flexLegend {
  display: flex;
  justify-content: space-evenly;
}

.label-wrapper {
  color: white;
  background: #1b2542;
  min-width: 120px;
  text-align: center;
  border-radius: 5px;
}

.label-header {
  background: #2c3655;
  padding: 2px 0px;
  border-radius: 5px 5px 0px 0px;
}

.label-title {
  text-align: center;
  padding-top: 5px;
}
.label-value {
  text-align: center;
  font-weight: bold;
  padding-bottom: 5px;
}
.flex-table-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}
.flex-table-actions__right > svg {
  margin-left: 15px;
  cursor: pointer;
}
.flex-table-actions__right > svg:hover {
  color: #11a1f1;
}
.close-wrapper {
  margin-right: 10px;
}
* {
  font-family: "-apple-system", "BlinkMacSystemFont", "SF Pro Display",
    "Helvetica Neue", "Segoe UI", Roboto;
  font-weight: 300;
}

.mt20 {
  margin-top: 20px;
}

.label-wrapper {
  color: white;
  background: #1b2542;
  min-width: 120px;
  text-align: center;
  border-radius: 5px;
}

.label-header {
  background: #2c3655;
  padding: 2px 0px;
  border-radius: 5px 5px 0px 0px;
}

.menu > li {
  cursor: pointer;
  color: black;
}

.label-title {
  text-align: center;
  padding-top: 5px;
}

.label-value {
  text-align: center;
  font-weight: bold;
  padding-bottom: 5px;
}

table * th {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
table * td {
  text-align: left;
}

.checkmark {
  width: 25px;
  margin-right: 15px;
}

.path {
  stroke-dasharray: 300;
  stroke-dashoffset: 0;
  -webkit-animation-name: load, spin;
  -webkit-animation-duration: 1s, 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-name: load, spin;
  animation-duration: 1s, 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50px 50px;
}

.path-complete {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.check {
  stroke-dasharray: 110;
  stroke-dashoffset: -110;
  stroke-width: 0;
}

.check-complete {
  -webkit-animation: check 1s ease-in forwards;
  animation: check 1s ease-in forwards;
  stroke-width: 10;
  stroke-dashoffset: 0;
}

.fill {
  stroke-dasharray: 285;
  stroke-dashoffset: -257;
  -webkit-animation: spin-fill 1s cubic-bezier(0.7, 0.435, 0.12, 0.6) infinite
    forwards;
  animation: spin-fill 1s cubic-bezier(0.7, 0.465, 0.12, 0.6) infinite forwards;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50px 50px;
}

.fill-complete {
  -webkit-animation: fill 1s ease-out forwards;
  animation: fill 1s ease-out forwards;
}

@-webkit-keyframes load {
  0% {
    stroke-dashoffset: 300;
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    stroke-dashoffset: 0;
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    stroke-dashoffset: -300;
  }
}
@keyframes load {
  0% {
    stroke-dashoffset: 285;
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    stroke-dashoffset: -285;
  }
}

@-webkit-keyframes check {
  0% {
    stroke-dashoffset: -110;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes check {
  0% {
    stroke-dashoffset: -110;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-fill {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(720deg);
  }
}
@keyframes spin-fill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

@-webkit-keyframes fill {
  0% {
    stroke-dashoffset: 285;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes fill {
  0% {
    stroke-dashoffset: 285;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.success {
  stroke: #009900;
  transition: stroke 0.6s;
}
.pagination {
  margin-bottom: 30px !important;
}
