.screenName {
  font-weight: 400;
  font-size: 20px;
  margin-left: 30px;
}
.leftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rightSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    color: white;
    font-weight: 400;
    margin-right: 50px;
    &:hover,
    &:focus,
    &:visited {
      color: white;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.compo {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  font-size: 14px;
}
.name {
  font-weight: 600;
}
.Who {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tab-label {
  font-size: 14px;
  padding: 12px 0px 0 0;
  display: inline-block;
  border: none;
  color: #777777;
  font-weight: 400;
  // border-bottom: solid 1px transparent;
  margin-right: 30px;
  cursor: pointer;
  outline: none;
  background: transparent;
}

.tab-label-active {
  font-weight: 700;
  color: #24ca7a;
}

.tab-label-active::after {
  content: "";
  display: block;
  height: 1px;
  position: relative;
  //   left: 25%;
  width: 100%;
  margin-top: 8px;
  background-color: #24ca7a;
}
.nav-dark::after {
  background-color: white;
}
