.nav-side-bar {
	height: 100%;
	background: white;
	box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	width: 80%;
	// max-width: 350px;
	z-index: 200;
	transform: translateX(-100%);
	transition: transform 0.3s ease-out;
}

.nav-side-bar-close {
	color: rgb(196, 196, 196);
	text-decoration: none;
	font-size: 1.2rem;
	cursor: pointer;
	text-align: right;
}

.nav-side-bar-close:hover {
	color: white;
}

.nav-side-bar.open {
	transform: translateX(0);
}

.nav-side-bar ul {
	height: fit-content;
	list-style: none;
	display: flex;
	flex-direction: column;
	justify-content: start;
	margin: 0;
}

.alignCenter {
	display: flex;
	align-items: center;
}

.sidebarLine {
	height: 2px;
	width: 100%;
	background-color: #ececec;
	margin: 1.5rem 0 1rem 0;
}

.firstList {
	li {
		a {
			color: #3e3e3e;
			margin: 8px 0;
			&:link {
				color: #3e3e3e;
			}
			&:hover {
				color: #3e3e3e;
			}
			&:active {
				color: #3e3e3e;
			}
			&:visited {
				color: #3e3e3e;
			}
		}
		img {
			margin-right: 8px;
			height: 1.2rem;
		}
		&:first-child {
			margin-top: 0;
		}
	}
}

.lastList {
	li {
		a {
			color: #777777;
			margin: 8px 0;
			font-size: 0.9rem;
			&:link {
				color: #777777;
			}
			&:hover {
				color: #777777;
			}
			&:active {
				color: #777777;
			}
			&:visited {
				color: #777777;
			}
		}
		img {
			margin-right: 8px;
			height: 1.1rem;
		}
		&:first-child {
			margin-top: 0;
		}
	}
}

.nav-side-bar a {
	margin: 0.5rem 0;
	color: rgb(196, 196, 196);
	text-decoration: none;
	font-size: 1rem;
}

.nav-side-bar a:hover,
.nav-side-bar a:active {
	color: white;
}

@media (min-width: 769px) {
	.nav-side-bar {
		display: none;
	}
}
