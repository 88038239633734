input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.modalinputs {
  display: flex;
  justify-content: space-between;
}

.line {
  margin: 15px -25px;
  height: 1px;
  background-color: #ececec;
  width: auto;
}
.title {
  font-weight: 700;
  color: #3e3e3e;
  font-size: 22px;
}
.APItitle {
  font-weight: 700;
  color: #3e3e3e;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer {
  background-color: #fbfbfb;
  margin: 25px -25px 0 -25px;
  width: auto;
  padding: 15px 25px;
  display: flex;
  justify-content: flex-end;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #ececec;
}
.para {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  margin-top: 5px;
}
