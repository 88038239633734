.screenName {
	font-weight: 400;
	font-size: 20px;
	margin-left: 30px;
}
.leftSide {
	display: flex;
	flex-direction: row;
	align-items: center;
	@media (max-width: 769px) {
		width: 100%;
	}
}
.rightSide {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.docs {
	a {
		display: flex;
		align-items: center;
		color: white;
		font-weight: 400;
		&:hover,
		&:focus,
		&:visited {
			color: white;
			text-decoration: none;
			cursor: pointer;
		}
	}
}
.upgrade {
	color: #24ca7a;
	font-weight: 400;
	margin: 0;
	font-weight: 700;
	color: #24ca7a;
	font-size: 14px;
	cursor: pointer;
	padding: 8px 20px;
	border: 1px solid #24ca7a;
	border-radius: 4px;

	&:hover {
		/* text-decoration: underline; */
		border-radius: 4px;
		background: #24ca7a;
		color: white;
	}
	&:visited {
		color: #24ca7a;
	}
}
.trailDays {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 400;
	color: white;
	margin-right: 15px;
	img {
		height: 18px;
		margin-right: 4px;
	}
}
.expired {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 400;
	color: white;
	margin-right: 15px;
	img {
		height: 18px;
		margin-right: 4px;
	}
}
.compo {
	display: flex;
	flex-direction: column;
	margin-left: 15px;
	font-size: 14px;
}
.name {
	font-weight: 600;
}
.Who {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.styledlink {
	padding: 0;
	width: 250px;
	img {
		margin: 0 12px;
		height: 18px;
		padding-top: 2px;
	}
	li {
		margin: 0;
		padding: 0.6rem 0.3rem;
		font-weight: 400;
		display: flex;
		width: 100%;
		align-items: center;
		border-bottom: 1px solid #ececec;
		&:nth-last-child(n) {
			border-bottom: none;
		}
		&:hover {
			background-color: #f3f3f3;
			&:nth-child(1) {
				background-color: white;
				cursor: default;
			}
		}
	}
}
.styledlinktoggle {
	padding: 0;
	width: 250px;
	img {
		margin-left: 12px;
		height: 18px;
		padding-top: 2px;
	}
	li {
		margin: 0;
		padding: (0.6rem) 1.2rem;
		font-weight: 400;
		display: flex;
		width: 100%;
		align-items: center;
		border-bottom: 1px solid #ececec;
		display: flex;
		justify-content: space-between;
		&:nth-child(1) {
			font-weight: 500;
			justify-content: start;
			padding: (0.6rem) 0.6rem;
			img {
				margin: 0 12px 0 0;
			}
		}
		&:nth-last-child(n) {
			border-bottom: none;
		}
		&:hover {
			background-color: #f3f3f3;
			&:nth-child(1) {
				background-color: white;
				cursor: default;
			}
		}
	}
}

.productStyledlink {
	padding: 0;
	img {
		margin: 0 12px;
		height: 18px;
		padding-top: 2px;
	}
	li {
		margin: 0;
		padding: 0.6rem 0.3rem;
		font-weight: 400;
		display: flex;
		width: 100%;
		align-items: center;
		color: #c4c4c4;
		&:hover {
			background-color: #3f3e4f;
			color: #fff;
		}
	}
}

.nav-label {
	font-size: 16px;
	padding: 5px 0px;
	display: inline-block;
	border: none;
	// border-bottom: solid 1px transparent;
	margin-right: 10px;
	cursor: pointer;
	outline: none;
	background: transparent;
	font-weight: 400;
	svg {
		width: 22px;
		height: 22px;
		margin-right: 16px;
	}
}

.nav-label-active {
	font-weight: 700;
	color: #24ca7a;
	img {
		fill: #24ca7a;
	}
}

// .nav-label-active::after {
//   content: "";
//   display: block;
//   height: 1px;
//   position: relative;
//   left: 25%;
//   width: 60%;
//   margin-top: 8px;
//   background-color: #444;
// }
// .nav-dark::after {
//   background-color: white;
// }

.nav-drawer-toggle {
	line-height: 25px;
	font-size: 25px;
	color: white;
	cursor: pointer;
}

.badge-icon[data-count]::after {
	position: relative;
	right: 15px;
	content: attr(data-count);
	font-size: 8px;
	padding: 4px 6px;
	border-radius: 999px;
	color: white;
	background: rgba(255, 0, 0, 0.85);
	text-align: center;
	font-weight: bold;
	cursor: pointer;
}

@media (max-width: 769px) {
	.nav-bar-list {
		display: none !important;
	}
}

@media (min-width: 769px) {
	.nav-drawer-toggle {
		display: none;
	}
}

.toolTipSwitch {
	position: relative;
	margin-left: auto;
}
.toolTipSwitch .toolTipTextSwitch {
	visibility: hidden;
	font-weight: 400;
	background-color: #254b47;
	color: #24ca7a;
	text-align: center;
	border-radius: 4px;
	padding: 2px 15px 3px 15px;

	position: absolute;
	width: max-content;
	min-width: max-content;
	top: -2px;
	left: -2px;
	z-index: 2;

	display: flex;
	align-items: center;

	@media only screen and (max-width: 786px) {
		left: auto;
		right: -2px;
		padding: 2px 15px 4px 15px;
	}
}

.toolTipSwitch:hover .toolTipTextSwitch {
	visibility: visible;
}
.bullet {
	width: 6px;
	min-width: 6px;
	height: 6px;
	border-radius: 50%;
	background: #24ca7a;
	margin-right: 10px;
}

// .toolTipSwitch .toolTipTextSwitch::after {
//   content: "";
//   position: absolute;
//   top: 50%;
//   right: 100%;
//   margin-top: -5px;
//   border-width: 5px;
//   border-style: solid;
//   border-color: transparent black transparent transparent;
// }
