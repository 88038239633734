.requestID {
  font-weight: 700;
  color: #777777;
  background-color: #d4ecff;
  border-radius: 10px;
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 14px;
}

/* request illust */
.requestIllus {
  padding-top: 25px;
  width: 70%;
  text-align: center;
  @media (max-width: 786px) {
    width: 90%;
  }
}

th {
  border-bottom: 0;
}
.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn {
  opacity: 1;
  width: 100px;
  height: 100px;
  transition: width 0.5s, height 0.5s, opacity 0.3s 0.3s;
}
.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50%;
  flex-grow: 1;
  padding: 2rem;
  background: white;
  border: 1.42px dashed #415c7e;
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: center;
}
.container {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: #f4f8fc;
}
.mainContainer {
  // width: 65%;
  width: 100%;
  padding: 0 22%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 30px; */
  @media (max-width: 786px) {
    padding: 0;
    overflow-x: hidden;
  }
}
.uploading {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 15%;
}
.uploadingBanner {
  margin-bottom: 20px;
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  display: flex;
  justify-content: space-between;
  color: #3e3e3e;
  font-size: 16px;
  padding-bottom: 5px;
}
.stepper {
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
}
.centerColumn {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bottomBanner {
  background-color: #d2e9f5;
  border-radius: 10px;
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  font-weight: 400;
  color: #2a5d7d;
  text-decoration: none;
}
a {
  font-weight: 400;
  text-decoration: none;
}

.hasTooltip {
  position: relative;
}
.hasTooltip .tooltiptext {
  visibility: hidden;

  // background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px;

  /* Position the tooltip */
  border: 1px solid rgb(236, 236, 236);
  position: absolute;
  width: 200px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  z-index: 1;
}

.hasTooltip:hover .tooltiptext {
  visibility: visible;
}
