input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

#container:first-child {
  /* border-radius: 5px 0 0 5px; */
  /* background-color: #dce6f2; */
  @media (max-width: 768px) {
    background-color: red;
  }
}
#container:last-child {
  /* border-radius: 0 5px 5px 0; */
  /* background-color: black; */
  @media (max-width: 768px) {
  }
}
.centerCoumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  height: 100%;
}
.ourmainslider {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
}
.slideContent {
  width: 100%;
  overflow-x: hidden;
  height: 100%;
}
.eachSlide {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
