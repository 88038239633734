.notification-side-bar {
    height: 100%;
    background: #FFF;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    width: 70%;
    max-width: 350px;
    z-index: 200;
    transform: translateX(+150%);
    transition: transform 0.3s ease-out;
}

.notification-side-bar-close {
    color: rgb(196, 196, 196);;
    text-decoration: none;
    font-size: 1.5rem;
    cursor: pointer;
    text-align: left;
    margin-left: -2rem;
    margin-top: 10px;
}

.notification-side-bar-close:hover {
    color: white;
}

.notification-side-bar.open {
    
    transform: translateX(0);
}

.notification-side-bar ul {
    margin-top: -3rem;
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.notification-side-bar a {
    margin: 0.5rem 0;
    color: rgb(196, 196, 196);;
    text-decoration: none;
    font-size: 1rem;
}

.notification-side-bar a:hover,
.notification-side-bar a:active {
    color: white;
}